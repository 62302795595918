import React from "react";
import { connect } from "react-redux";
import { withRouter } from "gl-commonui";
import { SchoolLocale } from "@app/locales/localeid";
import { MainTitle, Container } from "@app/components";
import { StateType } from "@app/states";
import {
    getPendingChanges,
    assignOwner,
    removeOwner,
    getPendingChangesDetail,
    approveDenyPending,
    bulkApproveDenyPending,
    resetChangeFieldData,
    setRefreshDataFlag,
    reload
} from "@app/states/cims";
import { PendingChangesHybridProps, PendingChangesHybrid } from "./component/pending-changes-hybrid";

const PendingChanges = (props: PendingChangesHybridProps) => {
    // const { TabPane } = Tabs;
    // const onTabChanged = (activeKey) => {
    //     const newFlags = { ...props.refreshData };
    //     newFlags[activeKey] = true;
    //     props.setRefreshDataFlag(newFlags);
    // };
    return (
        <Container fullWidth>
            <div className="cims pending-changes">
                <MainTitle title={SchoolLocale.CIMSPendingChangesPageTitle} />
                {/* <Tabs onChange={onTabChanged}>
                    <TabPane tab={fmtMsg({ id: SchoolLocale.CIMSPendingChangesSchoolTabTitle })} key={`${ChangeLogType.School}`}>
                        <PendingChanges4School {...props} />
                    </TabPane>
                    <TabPane tab={fmtMsg({ id: SchoolLocale.CIMSPendingChangesCampusTabTitle })} key={`${ChangeLogType.Campus}`}>
                        <PendingChanges4Campus {...props} />
                    </TabPane>
                    <TabPane tab={fmtMsg({ id: SchoolLocale.CIMSPendingChangesSchoolClassTabTitle })} key={`${ChangeLogType.SchoolClass}`}>
                        <PendingChanges4SchoolClass {...props} />
                    </TabPane>
                </Tabs> */}
                <PendingChangesHybrid {...props} />
            </div>
        </Container>
    );
};

export const PendingChangesPage = withRouter(
    connect(
        ({ cims: { refreshData, pendingChangesData, totalData, loading4Modal, changeFieldData, clearSelected, failedBulkApproveDeny, ownerUpdated } }: StateType) => ({
            refreshData,
            pendingChangesData,
            totalData,
            loading4Modal,
            changeFieldData,
            clearSelected,
            failedBulkApproveDeny,
            ownerUpdated
        }),
        { getPendingChanges, assignOwner, removeOwner, getPendingChangesDetail, approveDenyPending, bulkApproveDenyPending, resetChangeFieldData, setRefreshDataFlag, reload }
    )(PendingChanges)
);
