import React, {ReactElement, useEffect, useRef, useState} from "react";
import {RouteComponentProps} from "react-router-dom";
import {Icon, Input, Modal, Select} from "antd-min";
import {GLFormComponentProps, GLGlobal, GLLocale, GLUtil, PaginationParams, ResourceType} from "gl-commonui";
import {SchoolLocale} from "@app/locales/localeid";
import {CellType, GLGrid, GLGridColumnProps, GLGridSorter, toggleClass} from "@app/components/gl-grid";
import {
    ChangeLogType,
    Checkbox,
    CONSTS,
    DateHelper,
    escapeHtml,
    EventInfo,
    fmtMsg,
    GSSchoolAction,
    ListStates,
    regExpEscape
} from "@app/util";
import { CheckboxState } from "@app/util/enum";
import {
    ApproveDenyModalContentProps,
    ICIMSService,
    PendingChangeFieldModel,
    PendingChangeModel,
    PendingChangeOwnerModel,
    PendingChangePropsModel
} from "@app/service/cims";
import {ApproveDenyModal} from "./approve-deny-modal";
import {
    getChangeFields2Columns4PendingChanges,
    getCIMSChangeEventMessage,
    ResourceType2ChangeLogType
} from "./cims-consts";
import {AllowDragging, FlexGrid} from "wijmo/wijmo.grid";
import {Selector} from "wijmo/wijmo.grid.selector";
import {PathConfig} from "@app/config/pathconfig";
import {isEqual} from "lodash";
import {CIMSState} from "@app/states/cims";
import {ListStatesRadios} from "@app/components";
import {Column} from "@app/components/grid/grid-column";
import {useService} from "@app/hooks";
import {TYPES} from "@app/service/types";
import { canApproveDeny4ChangeType } from "./cims-consts";

export interface PendingChangesHybridProps
    extends RouteComponentProps<any>,
        GLFormComponentProps {
    pendingChangeType?: ChangeLogType;
    frozenColumns?: number;
    columnSortingMap: Map<string, string>;
    refreshData: {};
    loading4Modal?: boolean;
    pendingChangesData?: {};
    totalData?: {};
    changeFieldData?: any;
    clearSelected: boolean;
    failedBulkApproveDeny: any[];
    onFilterChangeFields?: (
        changeFields: PendingChangeFieldModel[]
    ) => PendingChangeFieldModel[];
    getPendingChanges: (query) => void;
    assignOwner: (query) => void;
    removeOwner: (query) => void;
    getPendingChangesDetail: (query) => void;
    approveDenyPending: (query) => void;
    bulkApproveDenyPending: (query) => void;
    resetChangeFieldData: (query) => void;
    setRefreshDataFlag: (query) => void;
    reload: (state: CIMSState) => void;
    ownerUpdated: boolean;
}

const defaultSorter: {
    columnName: string;
    columnSortName: string;
    ascending: boolean;
} = {
    columnName: PendingChangePropsModel.updateTime4ClientSort,
    columnSortName: PendingChangePropsModel.updateTime,
    ascending: false
};

export const renderApproveDenyAction = (
    text: string,
    columnName: string,
    pendingChange: PendingChangeModel,
    target: ChangeLogType
): any => {
    const columns = getChangeFields2Columns4PendingChanges(
        pendingChange,
        target,
        false
    );
    const displayText = text == null ? "" : text;
    if (columns.indexOf(columnName) >= 0) {
        const tip = fmtMsg({
            id: SchoolLocale.CIMSPendingChangesApproveDenyActionTip
        });
        return <div>
                <span>{displayText}</span>
            <a className='approve-deny-action' href='javascript:void(0)'>
                <span dangerouslySetInnerHTML={{__html: CONSTS.AntIcon2Html4ApproveDenyAction(tip)}}></span>
            </a>
        </div>;
    } else {
        return <span>{displayText}</span>;
    }
};

let updateSelected = false;

export const PendingChangesHybrid = (props: PendingChangesHybridProps) => {
    const cimsService = useService<ICIMSService>(TYPES.ICIMSService);
    const [filter, setFilter] = useState("");
    const [filterChange, setFilterChange] = useState(1);
    const [groupEnabled, setGroupEnabled] = useState(true);
    const [grid, setGrid] = useState(null);
    const gridRef = useRef(grid);
    const [sorter, setSorter] = useState(defaultSorter);
    const [subTitle, setSubTitle] = useState("");
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        hideOnSinglePage: true,
        pageSize: 50,
        onChange: null,
        showTotal: (total, range) =>
            fmtMsg(
                { id: GLLocale.Pagination },
                { from: range[0], to: range[1], total }
            )
    });
    const [approveDenyModalType, setApproveDenyModalType] = useState(null);
    const [approveDenyData, setApproveDenyData] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const previousSelectedItems = useRef(0);
    const [runUpdateEffect, setRunUpdateEffect] = useState(false);
    const [showFailedAppDnModel, setShowFailedAppDnModel] = useState(false);
    const [currentState, setCurrentState] = useState(ListStates.Active);
    const openModalItem = useRef(null);
    const listStateCounts = useRef({ allCnt: 0, activeCnt: 0, futureCount: 0 });
    const data = useRef([]); // Data of grid
    const [ownerOptions, setOwnerOptions] = useState<PendingChangeOwnerModel[]>([]);
    const [ownerOptionsLoading, setOwnerOptionsLoading] = useState(false);
    const [ownerOptionsCurrentIndex, setOwnerOptionsCurrentIndex] = useState<number|null>(null);
    const [gridLoading, setGridLoading] = useState(false);
    const topLeftCkeckboxState = useRef("");
    const [checkboxType, setCheckboxType] = useState(null);
    const isTopLeftChecked = useRef(true);
    const selectedItemsFromOtherPages = useRef([]);

    useEffect(() => {
        if (props.refreshData[`${props.pendingChangeType}`]) {
            const refreshDataFlag = { ...props.refreshData };
            refreshDataFlag[`${props.pendingChangeType}`] = false;
            props.setRefreshDataFlag(refreshDataFlag);
            getData(sorter, filter);
        }
    }, [props.refreshData[`${props.pendingChangeType}`]]);

    useEffect(() => {
        // update list count whenever the data count changes
        updateData();
        setPagination(pagination => {
            return {
                ...pagination,
                total: props.totalData[`${props.pendingChangeType}`],
                onChange: onPaginationChange
            };
        });
        // if (groupEnabled) {
        //     setFilterChange(new Date().getTime() + Math.random());
        // }
    }, [props.totalData]);

    useEffect(() => {
        if (props.clearSelected) {
            if (props.failedBulkApproveDeny.length) {
                setShowFailedAppDnModel(true);
            }
            setSelectedItems([]);
            props.reload({ clearSelected: false } as any);
        }
    }, [props.clearSelected]);

    useEffect(() => {
        if (props.ownerUpdated) {
            const allData = props.pendingChangesData[`${props.pendingChangeType}`];
            data.current = getSelectedStateData(allData);
            setGridLoading(false);
            props.reload({ ownerUpdated: false } as any);
        }
    }, [props.ownerUpdated]);

    const updateSelectedItems = () => {
        if (!gridRef.current) return;
        const grid = gridRef.current;
        let itemsSelectedFromOtherPages;
        if(selectedItemsFromOtherPages.current.length>0 && topLeftCkeckboxState.current===CheckboxState.Unchecked)
        {
             itemsSelectedFromOtherPages = selectedItemsFromOtherPages.current.filter(
                (i) => {
                    return (
                        grid.rows.findIndex((r) =>
                            isEqual(r.dataItem, i)
                        ) === -1
                    );
                }
            );
        }
        else{
             itemsSelectedFromOtherPages = selectedItems.filter(
                (i) => {
                    return (
                        grid.rows.findIndex((r) =>
                            isEqual(r.dataItem, i)
                        ) === -1
                    );
                }
            );
        }
        const currentSelectedFromPage = grid.rows
            .filter((r) => r.isSelected)
            .map((m) => m.dataItem);
        previousSelectedItems.current = selectedItems.length;
        setSelectedItems([
            ...itemsSelectedFromOtherPages,
            ...currentSelectedFromPage,
        ]);
    }
    useEffect(() => {
        if (!((topLeftCkeckboxState.current === CheckboxState.Unchecked || topLeftCkeckboxState.current == CheckboxState.Indeterminate )&& checkboxType == CellType.ColumnHeader)) {
            updateSelectedItems()
        }
    }, [runUpdateEffect]);
    
    useEffect(()=>{
        if(topLeftCkeckboxState.current===CheckboxState.Unchecked && isTopLeftChecked.current)
        {
            selectedItemsFromOtherPages.current=selectedItems;
            isTopLeftChecked.current=false;
        }
    })
    const getColumns = (
        { regionId, schoolId },
        allowAction = true
    ): GLGridColumnProps<PendingChangeModel>[] => {
        const cols = [
            {
                titleText: " ",
                dataIndex: undefined,
                width: props.totalData[`${props.pendingChangeType}`]
                    ? "0.5*"
                    : 0,
                className: "",
                align: "left"
            },
            {
                title: SchoolLocale.CIMSPendingChangesCaseNoColumn,
                dataIndex: PendingChangePropsModel.caseNo,
                width: "2*",
                className: "navigation-col",
            },
            {
                title: SchoolLocale.CIMSPendingChangesSchoolColumn,
                dataIndex: PendingChangePropsModel.schoolName,
                width: "2*",
                className: "navigation-col",
                render: (text, pendingChange, index) => {
                    const { regionId, schoolId } = pendingChange;
                    if (
                        GLGlobal.isActionValid(GSSchoolAction.SchoolLMS) &&
                        regionId &&
                        schoolId
                    ) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Schools,
                            { regionId, schoolId }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text}</span>`;
                    }
                }
            },
            {
                title: SchoolLocale.CIMSPendingChangesCampusColumn,
                dataIndex: PendingChangePropsModel.campusName,
                width: "2*",
                className: "navigation-col",
                render: (text, pendingChange, index) => {
                    if (!text) return "";
                    const { regionId, schoolId, campusId } = pendingChange;
                    if (
                        GLGlobal.isActionValid(GSSchoolAction.Campuses) &&
                        regionId &&
                        schoolId &&
                        campusId
                    ) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Classes,
                            { regionId, schoolId, campusId }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text}</span>`;
                    }
                }
            },
            {
                title: SchoolLocale.CIMSPendingChangesClassColumn,
                dataIndex: PendingChangePropsModel.schoolClassName,
                width: "2*",
                className: "navigation-col fixed-col",
                render: (text, pendingChange, index) => {
                    if (!text) return "";
                    const {
                        regionId,
                        schoolId,
                        campusId,
                        schoolClassId: classId
                    } = pendingChange;
                    if (
                        GLGlobal.isActionValid(GSSchoolAction.Campuses) &&
                        regionId &&
                        schoolId &&
                        campusId &&
                        classId
                    ) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Students,
                            { regionId, schoolId, campusId, classId }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text}</span>`;
                    }
                }
            },
            {
                title: SchoolLocale.CIMSPendingChangesChangeColumn,
                dataIndex: PendingChangePropsModel.changeType4Group,
                width: "3*",
                className: "hybrid-change-column",
                align: "left",
                render: (text, pendingChange, index) => {
                    const changeType = getCIMSChangeEventMessage(
                        ChangeLogType.Hybrid,
                        pendingChange
                    );
                    return renderApproveDenyAction(
                        changeType,
                        allowAction ? PendingChangePropsModel.changeType : "",
                        pendingChange,
                        ChangeLogType.Hybrid
                    );
                }
            },
            {
                title: SchoolLocale.CIMSPendingChangesUpdateByColumn,
                dataIndex: PendingChangePropsModel.updateBy,
                width: "2*"
            },
            {
                title: SchoolLocale.CIMSPendingChangesOwnerColumn,
                dataIndex: PendingChangePropsModel.ownerName,
                width: "2*",
                className: "owner-col"
            },
            {
                title: SchoolLocale.CIMSPendingChangesUpdateDateColumn,
                dataIndex: PendingChangePropsModel.updateTime4ClientSort,
                width: "2*",
                render: (text, pendingChange, index) => {
                    return renderApproveDenyAction(
                        DateHelper.formatDateTime2Local(
                            pendingChange.updateTime,
                            true,
                            null,
                            true
                        ),
                        PendingChangePropsModel.updateTime4ClientSort,
                        pendingChange,
                        ChangeLogType.Hybrid
                    );
                }
            }
        ];

        if (!allowAction) {
            return cols.slice(1);
        }
        return cols;
    };

    const getColumnComponents = (
        { regionId, schoolId },
        allowAction = true
    ): ReactElement[] => {
        const cols = [
            <Column
                key={"1"}
                width={props.totalData[`${props.pendingChangeType}`] ? "0.5*" : 0}
                align={"left"}
            />,
            <Column
                binding={PendingChangePropsModel.caseNo}
                sortMemberPath={PendingChangePropsModel.caseNo4ClientSort}
                header={fmtMsg(SchoolLocale.CIMSPendingChangesCaseNoColumn)}
                key={"2"}
                width={"1*"}
                cssClass={"navigation-col"}
            />,
            <Column
                binding={PendingChangePropsModel.schoolName}
                header={fmtMsg(SchoolLocale.CIMSPendingChangesSchoolColumn)}
                key={"3"}
                width={"2*"}
                cssClass={"navigation-col"}
                render={(value, item, context) => {
                    const { regionId, schoolId } = item;
                    if (
                        GLGlobal.isActionValid(GSSchoolAction.SchoolLMS) &&
                        regionId &&
                        schoolId
                    ) {
                        return <a href={GLUtil.pathStringify(
                            PathConfig.Schools,
                            { regionId, schoolId }
                        )} > <span>{value}</span> </a>;
                    } else {
                        return <span>{value}</span>;
                    }
                }}
            />,
            <Column
                binding={PendingChangePropsModel.campusName}
                header={fmtMsg(SchoolLocale.CIMSPendingChangesCampusColumn)}
                key={"4"}
                width={"2*"}
                cssClass={"navigation-col"}
                render={(value, item, context) => {
                    if (!value) return "";
                    const { regionId, schoolId, campusId } = item;
                    if (
                        GLGlobal.isActionValid(GSSchoolAction.Campuses) &&
                        regionId &&
                        schoolId &&
                        campusId
                    ) {
                        return <a href={GLUtil.pathStringify(
                            PathConfig.Classes,
                            { regionId, schoolId, campusId }
                        )} > <span>{value}</span> </a>;
                    } else {
                        return <span>{value}</span>;
                    }
                }}
            />,
            <Column
                binding={PendingChangePropsModel.schoolClassName}
                header={fmtMsg(SchoolLocale.CIMSPendingChangesClassColumn)}
                key={"5"}
                width={"2*"}
                cssClass={"navigation-col fixed-col"}
                render={(value, item) => {
                    if (!value) return "";
                    const {
                        regionId,
                        schoolId,
                        campusId,
                        schoolClassId: classId
                    } = item;
                    if (
                        GLGlobal.isActionValid(GSSchoolAction.Campuses) &&
                        regionId &&
                        schoolId &&
                        campusId &&
                        classId
                    ) {
                        return <a href={GLUtil.pathStringify(
                                    PathConfig.Students,
                                    { regionId, schoolId, campusId, classId }
                        )} > <span>{value}</span> </a>;
                    } else {
                        return <span>{value}</span>;
                    }
                }}
            />,
            <Column
                binding={PendingChangePropsModel.changeType4Group}
                header={fmtMsg(SchoolLocale.CIMSPendingChangesChangeColumn)}
                key={"6"}
                width={"3*"}
                cssClass={"hybrid-change-column"}
                align={"left"}
                render={(value, item) => {
                    const changeType = getCIMSChangeEventMessage(
                        ChangeLogType.Hybrid,
                        item
                    );
                    return renderApproveDenyAction(
                        changeType,
                        allowAction ? PendingChangePropsModel.changeType : "",
                        item,
                        ChangeLogType.Hybrid
                    );}
                }
            />,
            <Column
                binding={PendingChangePropsModel.updateBy}
                header={fmtMsg(SchoolLocale.CIMSPendingChangesUpdateByColumn)}
                key={"7"}
                width={"2*"}
            />,
            <Column
                binding={PendingChangePropsModel.ownerName}
                sortMemberPath={PendingChangePropsModel.ownerName}
                header={fmtMsg(SchoolLocale.CIMSPendingChangesOwnerColumn)}
                cssClass={"owner-col"}
                key={"8"}
                width={"2*"}
                render={(value, item, context) => {
                    return ownerSelector(item, context.row.dataIndex);
                }}
            />,
            <Column
                binding={PendingChangePropsModel.updateTime4ClientSort}
                header={fmtMsg(SchoolLocale.CIMSPendingChangesUpdateDateColumn)}
                key={"9"}
                width={"2*"}
                render={(value, item) => {
                    return renderApproveDenyAction(
                        DateHelper.formatDateTime2Local(
                            item.updateTime,
                            true,
                            null,
                            true
                        ),
                        PendingChangePropsModel.updateTime4ClientSort,
                        item,
                        ChangeLogType.Hybrid
                    )}
                }
            />,
        ];

        if (!allowAction) {
            return cols.slice(1);
        }
        return cols;
    };

    const ownerSelector = (item: PendingChangeModel, index: number) => {
        const isCurrent = ownerOptionsCurrentIndex === index;
        return <Select
                showSearch
                filterOption={(input, option) =>
                typeof option.props.children === "string" ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                }
                disabled={!item.changesGroupId}
                value={item.ownerId || undefined}
            onDropdownVisibleChange={(open) => onOwnerDropdownOpen(open, item, index)}
                onChange={(value, option) => onOwnerChange(value, option, item)}
                loading={isCurrent && ownerOptionsLoading}
            placeholder={fmtMsg(SchoolLocale.CIMSPendingChangesOwnerSelectPlaceholder)}
                allowClear={true}
            dropdownRender={menu => (
                    <>
                    {
                        isCurrent && ownerOptionsLoading ? <div key={"owner-loading"} className={"owner-loading-dropdown"}>
                                <Icon type={"loading"} />
                        </div> : menu
                    }
                    </>
                )}
            >
            {!isCurrent && item.ownerId && <Select.Option value={item.ownerId}>{item.ownerName}</Select.Option>}
            {isCurrent && ownerOptions.map((owner) => <Select.Option value={owner.userId} data-name={owner.name}>{owner.name}</Select.Option>)}
            </Select>
    }

    const onOwnerDropdownOpen = (open: boolean, item: PendingChangeModel, index: number) => {
        if (open) {
            setOwnerOptions([]);
            setOwnerOptionsLoading(true);
            setOwnerOptionsCurrentIndex(index);
            cimsService.getOwners(item.regionId, item.schoolId).then((res) => {
                setOwnerOptions(res);
                setOwnerOptionsLoading(false);
            });
        } else {
            setOwnerOptions([]);
            setOwnerOptionsLoading(false);
            setOwnerOptionsCurrentIndex(null);
        }
    };

    const onOwnerChange = (value: string | undefined, option: ReactElement, item: PendingChangeModel) => {
        const { assignOwner, removeOwner, pendingChangeType } = props;
        if (value && item.changesGroupId) {
            assignOwner({
                changesGroupId: item.changesGroupId,
                ownerId: value,
                ownerName: option.props.children,
                target: pendingChangeType
            });
        } else if (!value) {
            removeOwner({
                changesGroupId: item.changesGroupId,
                target: pendingChangeType
            })
        }
    }

    const getColumnSorter = (sorter: GLGridSorter) => {
        if (!sorter) return null;
        if (sorter.columnName == PendingChangePropsModel.campusName) {
            return {
                sortBy: [
                    PendingChangePropsModel.campusName,
                    PendingChangePropsModel.schoolClassName
                ],
                isDescending: [!sorter.ascending, !sorter.ascending]
            };
        } else {
            return {
                sortBy: sorter.columnSortName,
                isDescending: !sorter.ascending
            };
        }
    };

    const getData = (
        sorter: GLGridSorter,
        keyword?: string,
        group: boolean = null,
        currentPage: number = null
    ) => {
        const { pendingChangeType: logType, getPendingChanges } = props;
        const hasGroup = group == null ? groupEnabled : group;
        const pagingParams = hasGroup
            ? null
            : new PaginationParams(
                  currentPage == null ? pagination.current : currentPage,
                  pagination.pageSize
              ).toRequest();
        getPendingChanges({
            target: logType,
            keyword: hasGroup ? "" : keyword,
            groupEnabled,
            ...pagingParams,
            ...getColumnSorter(sorter)
        });
    };

    const onQuery = (value, e) => {
        e.preventDefault();
        if (
            e.currentTarget &&
            e.currentTarget.tagName.toLowerCase() == "A".toLowerCase()
        ) {
            setGroupEnabled(value);
            getData(sorter, filter, value);
        } else {
            setFilter(value);
            if (groupEnabled) {
                // update count
                const allData = props.pendingChangesData[`${props.pendingChangeType}`];
                const filteredData = allData.filter((item) => onFilter(item, value));
                updateCount(filteredData, true);
                setFilterChange(new Date().getTime() + Math.random());
            } else {
                getData(sorter, value);
            }
        }
        setPagination(pagination => {
            return {
                ...pagination,
                current: 1
            };
        });
        setSelectedItems([]);
    };

    const onPaginationChange = (page: number, pageSize?: number) => {
        setPagination(pagination => {
            return {
                ...pagination,
                current: page
            };
        });
        if (!groupEnabled) {
            getData(sorter, filter, null, page);
        }
    };

    const onFilter = (item: PendingChangeModel, filterArg?: string) => {
        filterArg = typeof filterArg === "string" ? filterArg : filter;
        return (
            !groupEnabled ||
            filterArg.length == 0 ||
            (item.regionName &&
                item.regionName.toLowerCase().indexOf(filterArg.toLowerCase()) >
                    -1) ||
            (item.schoolName &&
                item.schoolName.toLowerCase().indexOf(filterArg.toLowerCase()) >
                    -1) ||
            (item.campusName &&
                item.campusName.toLowerCase().indexOf(filterArg.toLowerCase()) >
                    -1) ||
            (item.schoolClassName &&
                item.schoolClassName
                    .toLowerCase()
                    .indexOf(filterArg.toLowerCase()) > -1)
        );
    };

    const onGridViewChanged = view => {
        if (groupEnabled) {
            setPagination(pagination => {
                return {
                    ...pagination,
                    total: view.totalItemCount,
                    onChange: onPaginationChange
                };
            });
        }
    };

    const onFormatColumnHeader = (grid, e) => {
        if (
            grid.columns[e.col].binding ==
            PendingChangePropsModel.schoolClassName
        ) {
            toggleClass(e.cell, "fixed-col", true);
        }
    };

    const onFormatGroupPanel = (groupHtml, groupData) => {
        if (!groupHtml) return null;
        const modelValue = groupData.dataItem.name;
        const htmlValue = `<b>${typeof modelValue == "string" ? escapeHtml(modelValue) : modelValue
        }</b>`;
        let customizedHtmlValue = null;
        const regExp = new RegExp(regExpEscape(htmlValue), "i");
        const parts = groupHtml.split(regExp);
        const countPart = parts[1]
            ? parts[1].replace(/\d+/g, groupData.dataItem.items.length)
            : "";
        switch (groupData.dataItem.groupDescription.propertyName) {
            case PendingChangePropsModel.changeType4Group:
                customizedHtmlValue = getCIMSChangeEventMessage(
                    ChangeLogType.Hybrid,
                    groupData.dataItem.items[0]
                );
                break;
            case PendingChangePropsModel.startDate4ClientSort:
                const startDate =
                    groupData.dataItem.items.length > 0
                        ? groupData.dataItem.items[0].startDate4Display
                        : "";
                customizedHtmlValue = startDate
                    ? `<b>${DateHelper.formatDate2Local(startDate)}</b>`
                    : "";
                break;
            case PendingChangePropsModel.updateTime4ClientSort:
                const value =
                    groupData.dataItem.items.length > 0
                        ? groupData.dataItem.items[0].updateTime4Display
                        : "";
                customizedHtmlValue = value
                    ? `<b>${DateHelper.formatDateTime2Local(
                          value,
                          false,
                          null,
                          true
                      )}</b>`
                    : "";
                break;
            default:
                customizedHtmlValue = htmlValue;
                break;
        }
        return countPart
            ? `${parts[0]}<b>${customizedHtmlValue}</b>${countPart}`
            : null;
    };

    const onFormatCell = (
        grid: FlexGrid,
        pendingChange: PendingChangeModel,
        e
    ) => {
        gridLoading && e.row == grid.rows.length - 1 && setGridLoading(false);

        if (e.col == 0) {
            // only check when first column is rendered
            // to check the items on pagination change
            const curItem = grid.rows[e.row].dataItem;
            if (
                selectedItems.findIndex(i => isEqual(i, curItem)) > -1 &&
                !grid.rows[e.row].isSelected
            ) {
                grid.rows[e.row].isSelected = true;
            }

            if (
                !canApproveDeny4ChangeType(
                    ResourceType2ChangeLogType.get(pendingChange.resourceType),
                    pendingChange.changeType
                )
            ) {
                e.cell.classList.add("wj-state-disabled");
                grid.rows[e.row].isSelected = false;
                e.cell.checked = false;
            }
        }
    };

    const overrideResize = (s: FlexGrid, e: Event, resize: () => void) => {
        if (previousSelectedItems.current !== selectedItems.length) {
            setTimeout(
                () => (previousSelectedItems.current = selectedItems.length),
                3000
            );
            return;
        }
        resize();
    };

    const initGrid = (grid: FlexGrid) => {
        const selector = new Selector(grid, {
            itemChecked: (s, e) => {       
                updateSelected = !updateSelected;
                setRunUpdateEffect(updateSelected);
            }
        });

        grid.hostElement.addEventListener(
            "mousedown",
            function (e) {
                let ht = grid.hitTest(e);
                setCheckboxType(ht.cellType);
                if (
                    e.target.type === Checkbox &&
                    ht.cellType == CellType.ColumnHeader
                ) {
                    e.preventDefault();
                    const headerCbCkeckbox = grid.columnHeaders.getCellElement(
                        0,
                        0
                    ).firstChild.firstChild;
                    if (headerCbCkeckbox.indeterminate == true) {
                        topLeftCkeckboxState.current = CheckboxState.Indeterminate;
                    } else if (headerCbCkeckbox.checked == false) {
                        topLeftCkeckboxState.current = CheckboxState.Unchecked;
                    } 
                }
            },
            false
        );

        grid.hostElement.addEventListener("click", function (e) {
            let ht = grid.hitTest(e);

            if (ht.cellType == CellType.ColumnHeader) {
                let headerCb = grid.columnHeaders.getCellElement(0, 0);
                let headerCbCkeckbox = headerCb.childNodes[0].childNodes;

                if (topLeftCkeckboxState.current ===CheckboxState.Indeterminate) {
                    headerCbCkeckbox[0].checked = false;
                    setSelectedItems([]);
                    for (let rowIndex = 0; rowIndex < pagination.pageSize; rowIndex++) {
                        grid.rows[rowIndex].isSelected = false;
                    }
                }
            }
        });
  
          grid.updatedLayout.addHandler(function(s,e){
              if(topLeftCkeckboxState.current===CheckboxState.Unchecked)
              {
                  updateSelectedItems();
                  topLeftCkeckboxState.current=CheckboxState.Indeterminate;
                  isTopLeftChecked.current=true;
              }
        })
    };
    const onGridLoaded = (grid) => {
        gridRef.current = grid;
        setGrid(grid);
    };

    const onLoadingRows = grid => {
        !gridLoading &&
            grid.itemsSource &&
            grid.itemsSource.items &&
            grid.itemsSource.items.length > 0 &&
            setGridLoading(true);
    };

    const onGridClick = event => {
        if (!gridRef.current) return;
        let ht = gridRef.current.hitTest(event);
        if (gridRef.current.columns[ht.col].cssClass === "owner-col") {
            return;
        }
        if (
            ht.cellType == CellType.Cell &&
            (event.target.tagName == "A" ||
                event.target.tagName == "svg" ||
                event.target.tagName == "path")
        ) {
            const item: PendingChangeModel =
                gridRef.current.rows[ht.row].dataItem;
            setSubTitle(getSubTitle(item));
            onOpenApproveDenyModal(
                item.resourceType,
                item.schoolId,
                item.campusId,
                item.schoolClassId,
                [item.changeType],
                item.isFutureClassChange,
                item.isInCurrentAndFuture,
                item
            );
        }
    };

    const getSubTitle = (item: PendingChangeModel) => {
        switch (item.resourceType) {
            case ResourceType.School:
                return item.schoolName;
            case ResourceType.Campus:
                return item.changeType == EventInfo.PendingChangesMaterialOrder
                    ? item.materialRequestId
                    : item.campusName;
            case ResourceType.SchoolClass:
                return item.schoolClassName;
        }
    };

    const onOpenApproveDenyModal = (
        resourceType,
        schoolId,
        campusId,
        classId,
        types,
        isFutureClassChange,
        isInCurrentAndFuture,
        item
    ) => {
        const { getPendingChangesDetail } = props;
        if (types.length == 0) return;
        setApproveDenyModalType(types[0]);
        const approveData = {
            target: ResourceType2ChangeLogType.get(resourceType),
            schoolId,
            campusId,
            classId,
            types,
            isFutureClassChange,
            isInCurrentAndFuture
        };
        openModalItem.current = item;
        setApproveDenyData(approveData);
        getPendingChangesDetail(approveData);
    };

    const onApproveModal = event => {
        event.preventDefault();
        onApprove();
    };

    const onDenyModal = data => {
        onApprove(false, data);
    };

    const onApprove = (
        approve: boolean = true,
        data?,
        isbulk: boolean = false
    ) => {
        const { approveDenyPending, bulkApproveDenyPending } = props;
        const pagingParams = groupEnabled
            ? null
            : new PaginationParams(pagination.current, 50).toRequest();
        const rest = {
            keyword: groupEnabled ? "" : filter,
            groupEnabled,
            ...pagingParams,
            ...getColumnSorter(sorter)
        };
        if (!isbulk) {
            // remove item being ack or denied from bulk selection
            const newSelectedItems = selectedItems.filter(i => {
                const itemTarget = ResourceType2ChangeLogType.get(
                    i.resourceType
                );
                return !(
                    i.schoolClassId === approveDenyData.classId &&
                    approveDenyData.types.some(s => s === i.changeType) &&
                    itemTarget === approveDenyData.target
                );
            });
            setSelectedItems(newSelectedItems);

            const eventId = getFirstChangeEventId(openModalItem.current);

            if (openModalItem && openModalItem.current.changeType === EventInfo.PendingChangesMovePromoteStudents && openModalItem.current.isInCurrentAndFuture) {
                const title = approve
                    ? fmtMsg(
                        { id: SchoolLocale.CIMSPendingAcknowledgeCurrentFuture }
                    )
                    : fmtMsg(
                        { id: SchoolLocale.CIMSPendingDenyCurrentFuture }
                    );

                Modal.confirm({
                    title,
                    onOk: () =>
                        new Promise(res => {
                            approveDenyPending({
                                ...approveDenyData,
                                approve,
                                data,
                                eventId,
                                ...rest
                            });
                            onCloseModal();
                            res(null);
                        })
                });
            }
            else {
                approveDenyPending({
                    ...approveDenyData,
                    approve,
                    data,
                    eventId,
                    ...rest
                });
                onCloseModal();
            }
        } else {
            const items = selectedItems.map(i => {
                const eventId = getFirstChangeEventId(i);
                return {
                    ...i,
                    target: ResourceType2ChangeLogType.get(i.resourceType),
                    schoolId: i.schoolId,
                    campusId: i.campusId,
                    schoolClassId: i.schoolClassId,
                    types: [i.changeType],
                    eventId
                };
            });
            const isAnyInCurrentAndFuture = items.some(i => typeof i.isInCurrentAndFuture === "boolean" && i.isInCurrentAndFuture);

            // confirm
            const title = approve
                ? isAnyInCurrentAndFuture ? fmtMsg(
                    { id: SchoolLocale.CIMSPendingAcknowledgeCurrentFutureAll },
                    { number: selectedItems.length }) : fmtMsg(
                          { id: SchoolLocale.CIMSPendingAcknowledgeAll },
                          { number: selectedItems.length }
                      )
                : isAnyInCurrentAndFuture ? fmtMsg(
                      { id: SchoolLocale.CIMSPendingDenyCurrentFutureAll },
                      { number: selectedItems.length }
                ) : fmtMsg(
                      { id: SchoolLocale.CIMSPendingDenyAll },
                      { number: selectedItems.length }
                  );

            Modal.confirm({
                title,
                onOk: () =>
                    new Promise(res => {
                        bulkApproveDenyPending({ items, approve, ...rest });
                        res(null);
                    })
            });
        }
    };

    const getFirstChangeEventId = curItem => {
        // since material order will always have one orderid
        // and eventId is only used by material order for now
        return curItem &&
            Array.isArray(curItem.changes) &&
            curItem.changes.length
            ? curItem.changes[0].id
            : null;
    };

    const onCloseModal = () => {
        setApproveDenyData(null);
        setApproveDenyModalType(null);
        props.resetChangeFieldData({});
        setGridLoading(false);
    };

    const renderApproveDenyModal = (props) => {
        // Material order event data is not grouped. So passing the required information using props.
        // Other event data is fetched from API and then used from redux state.
        const {
            regionId,
            schoolId,
            campusId,
            campusName,
            materialOrderId,
            materialRequestId,
            updateBy: createdBy,
            updateTime: createdDate,
        } = openModalItem.current;

        const materialEventInfo = {
            regionId,
            schoolId,
            campusId,
            materialOrderId,
            materialRequestId,
        };

        const item =
            approveDenyModalType === EventInfo.PendingChangesMaterialOrder &&
            approveDenyData.target === ChangeLogType.Campus
                ? [
                      {
                          createdBy,
                          createdDate:
                              DateHelper.toLocalTimeStringFromUTC(createdDate),
                          campusName,
                      },
                  ]
                : props.changeFieldData;

        const approveDenyModalProps: ApproveDenyModalContentProps<PendingChangeModel> =
            {
                ...(approveDenyModalType ===
                EventInfo.PendingChangesMaterialOrder
                    ? materialEventInfo
                    : {}),
                target: approveDenyData.target,
                changeType: approveDenyModalType,
                visible: approveDenyModalType != null,
                loading: props.loading4Modal,
                subTitle,
                item,
                history: props.history,
                onApprove: onApproveModal,
                onDeny: onDenyModal,
                onCancel: onCloseModal,
            };
        return <ApproveDenyModal {...approveDenyModalProps} />;
    };

    const renderFailedApproveDenyModel = () => {
        const modalWidth =
            window.innerWidth - 200 <= 500
                ? window.innerWidth
                : window.innerWidth - 200;
        const modalHeight =
            window.innerHeight - 200 <= 350
                ? window.innerHeight
                : window.innerHeight - 300;
        const onOkClose = () => {
            setShowFailedAppDnModel(false);
            setTimeout(() => props.reload({ failedBulkApproveDeny: [] }), 100);
        };

        return (
            <Modal
                visible={showFailedAppDnModel}
                width={modalWidth}
                okText={fmtMsg({
                    id: SchoolLocale.CIMSPendingChangesFailedApproveDenyModalOk,
                })}
                onOk={onOkClose}
                onCancel={onOkClose}
                cancelButtonProps={{ style: { visibility: "hidden" } }}
                title={fmtMsg({
                    id: SchoolLocale.CIMSPendingChangesFailedApproveDeny,
                })}
            >
                <div>
                    <GLGrid
                        allowDragging={AllowDragging.None}
                        allowGrouping={false}
                        className="pending-changes-grid"
                        style={{ maxHeight: `${modalHeight}px` }}
                        columns={getColumns({}, false)}
                        dataSource={props.failedBulkApproveDeny}
                        loading={false}
                        defaultSorter={sorter}
                        allowSorting={false}
                        autoResponsive={false}
                        pagination={false}
                        onFormatColumnHeader={onFormatColumnHeader}
                    />
                </div>
            </Modal>
        );
    };

    const onColumnSorting = (sorter: GLGridSorter) => {
        const { columnSortingMap } = props;
        const columnName = columnSortingMap.has(sorter.columnSortName)
            ? columnSortingMap.get(sorter.columnSortName)
            : sorter.columnSortName;
        setSorter({
            columnName: sorter.columnName,
            columnSortName: columnName,
            ascending: sorter.ascending,
        });
        getData({ ...sorter, columnSortName: columnName }, filter);
    };

    const onListStateChange = (state: ListStates) => {
        if (state === currentState) {
            return;
        }
        updateData(state);
        setCurrentState(state);
        setSelectedItems([]);
    };

    const renderListStates = () => {
        return (
            <ListStatesRadios
                {...listStateCounts.current}
                onChange={onListStateChange}
                value={currentState}
                showInActiveRadio={false}
                activeTextLocaleId={SchoolLocale.CIMSListStateCurrent}
            />
        );
    };

    /**
     * Updates grid data and list state count
     * @param allData
     * @param state
     * @returns
     */
    const getSelectedStateData = (
        allData: PendingChangeModel[],
        state?: ListStates
    ) => {
        updateCount(allData);
        const nextState = typeof state === "number" ? state : currentState;
        if (!allData || !Array.isArray(allData)) {
            return [];
        }
        if (nextState === ListStates.All) {
            return allData;
        } else if (nextState === ListStates.Active) {
            return allData.filter((f) => !f.isFutureClassChange);
        } else if (nextState === ListStates.Future) {
            return allData.filter((f) => f.isFutureClassChange);
        } else {
            return [];
        }
    };

    /**
     * Updates the count of list states
     * @param allData
     * @param filtered if data is filtered by user keyword
     * @returns
     */
    const updateCount = (allData: PendingChangeModel[], filtered?: boolean) => {
        if (!allData || !Array.isArray(allData)) {
            listStateCounts.current = {
                activeCnt: 0,
                futureCount: 0,
                allCnt: 0,
            };
            return;
        }
        allData = filtered ? allData : allData.filter((item) => onFilter(item));
        const currentCount = allData.filter(
            (f) => !f.isFutureClassChange
        ).length;
        listStateCounts.current = {
            allCnt: allData.length,
            activeCnt: currentCount,
            futureCount: allData.length - currentCount,
        };
    };

    /**
     * Updates the data according to provided state or 'currentState'
     * Before updating checks if data has been changed.
     * @param state
     * @returns
     */
    const updateData = (state?: ListStates) => {
        const allData = props.pendingChangesData[`${props.pendingChangeType}`];
        const updatedData = getSelectedStateData(allData, state);
        if (data.current.length !== updatedData.length) {
            data.current = updatedData;
            return;
        }
        if (
            !data.current.every((s) =>
                updatedData.some(
                    (u) =>
                        u.schoolClassId === s.schoolClassId &&
                        u.campusId === s.campusId &&
                        u.changeType === s.changeType
                )
            )
        ) {
            data.current = updatedData;
        }
    };

    const render = () => {
        const {
            frozenColumns,
            match: { params },
            location: { state },
        } = props;

        return (
            <React.Fragment>
                <div className="pending-changes-condition pending-changes-condition--block">
                    {renderListStates()}
                    <div className="pending-changes-filter">
                        <Input.Search
                            placeholder={fmtMsg({
                                id: SchoolLocale.CIMSPendingChangeSearchPlaceholder4Class,
                            })}
                            defaultValue={state && state.filter}
                            onSearch={onQuery}
                        />
                    </div>
                    <div className="pending-changes-archive-switch">
                        {!groupEnabled && (
                            <a onClick={(e) => onQuery(!groupEnabled, e)}>
                                {fmtMsg({
                                    id: SchoolLocale.CIMSPendingChangesEnableGroupSwitch,
                                })}
                            </a>
                        )}
                    </div>
                </div>
                <div className="pending-cims__ak-dn">
                    <a
                        className={
                            selectedItems.length === 0 &&
                            "pending-cims__ak-dn--disabledlink"
                        }
                        onClick={
                            selectedItems.length > 0
                                ? () => onApprove(true, undefined, true)
                                : () => {
                                      return false;
                                  }
                        }
                    >
                        {fmtMsg({
                            id: SchoolLocale.CIMSPendingAcknowledgeSelectedTxt,
                        })}
                    </a>
                    &nbsp;
                    {selectedItems.length > 0 && (
                        <span>({selectedItems.length})</span>
                    )}
                    &nbsp;{fmtMsg({ id: SchoolLocale.CIMSPendingorTxt })}&nbsp;
                    <a
                        className={
                            selectedItems.length === 0 &&
                            "pending-cims__ak-dn--disabledlink"
                        }
                        onClick={
                            selectedItems.length > 0
                                ? () => onApprove(false, undefined, true)
                                : () => {
                                      return false;
                                  }
                        }
                    >
                        {fmtMsg({
                            id: SchoolLocale.CIMSPendingDenySelectedTxt,
                        })}
                    </a>
                    &nbsp;
                    {selectedItems.length > 0 && (
                        <span>({selectedItems.length})</span>
                    )}
                </div>
                <GLGrid
                    allowDragging={AllowDragging.Columns}
                    allowGrouping={groupEnabled}
                    filterChange={filterChange}
                    className="pending-changes-grid"
                    columnComponents={getColumnComponents(params)}
                    dataSource={data.current}
                    defaultSorter={sorter}
                    frozenColumns={frozenColumns}
                    onColumnSorting={onColumnSorting}
                    sortInClient={groupEnabled}
                    pagingInClient={groupEnabled}
                    pagination={pagination}
                    loading={gridLoading}
                    autoResponsive={false}
                    onLoadingRows={onLoadingRows}
                    onGridLoaded={onGridLoaded}
                    onFilter={onFilter}
                    onFormatGroupPanel={onFormatGroupPanel}
                    onGridClick={onGridClick}
                    onFormatCell={onFormatCell}
                    onFormatColumnHeader={onFormatColumnHeader}
                    onGridViewChanged={onGridViewChanged}
                    onInitializeGrid={initGrid}
                    overrideResize={overrideResize}
                    preserveSort={true}
                />
                {approveDenyModalType != null &&
                    approveDenyData != null &&
                    renderApproveDenyModal(props)}
                {renderFailedApproveDenyModel()}
            </React.Fragment>
        );
    };

    return render();
};

PendingChangesHybrid.defaultProps = {
    pendingChangeType: ChangeLogType.Hybrid,
    frozenColumns: 3,
    columnSortingMap: new Map<string, string>([
        [
            PendingChangePropsModel.updateTime4ClientSort,
            PendingChangePropsModel.updateTime,
        ],
    ]),
};
